<template>
  <div class="product-wrap product-integrate">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-integrate"></div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>我们能提供什么</h4></div>
        <div class="txt-box align-ct">
          <p>云昇自动化工程事业部可以为用户提供：</p>
          <p>Siemens系列、Modicon系列、AB系列、GE系列、ABB系列及和利时等各类PLC及DCS控制系统；</p>
          <p>iFix软件、Intouch软件、Citect软件、RSView32软件、WinCC软件、PCS7软件、组态王软件等SCADA系统；</p>
          <p>各种MCC动力柜、PLC控制柜、变频器柜等电气成套设备；</p>
          <p>机器人、智能工厂自动化项目及机电安装工程；</p>
          <p>信息系统软件及监控系统开发；</p>
          <p>各类电气控制及自动化系统总包交钥匙工程等。</p>
        </div>
        <div class="auto-img" style="margin: 2% auto">
          <img src="../../assets/img/product/integrate/pic_1.png" alt="云昇自动化系统实施框架">
        </div>

        <div class="txt-box align-ct no-indent">
          <p>可提供电气成套产品，包括：PLC柜、控制柜、低压抽屉柜（MNS、GCK）、GGD开关柜、电容柜、仪表柜、电源柜、</p>
          <p>软启动柜、变频柜、动力配电柜、插座箱、照明箱、防雨配电箱、自动化控制设备、电缆桥架施工等。</p>
        </div>

        <div class="fz-sm align-ct mt-5"><h4>污水处理厂</h4></div>

        <div class="txt-box align-ct no-indent">
          <p>根据污水处理厂工艺要求,在污水处理厂配置必要的检测仪表、可编程控制器(PLC) 、工业 PC 机和通信系统,并构成计算机测控管理系统。</p>
          <p>根据工程工艺要求，在各个工艺段配置必要的检测仪表进行关键工艺的检测；利用可编程控制器对各种设备进行有序控制，使各种工艺按要求执行；</p>
          <p>通过通信系统在工业 PC 机上，设置体现各种设备、仪表参数的图形控制画面，从而构成计算机测控管理系统。</p>
        </div>

        <div class="fz-sm align-ct mt-5"><h4>自来水厂</h4></div>

        <div class="txt-box align-ct no-indent">
          <p>自来水厂净水生产自动化的概念就是采用各种在线式水质分析仪表， </p>
          <p> 对净水处理生产流程中的各项工艺技术指标进行连续检测，并以直观的形式将这些指标实时反馈给自动化控制系统，</p>
          <p>控制系统便可根据在线检测的结果对生产工艺设备进行及时调整，从而使制水生产的可靠性和稳定性得到充分的保障。</p>
        </div>

        <div class="fz-sm align-ct mt-5"><h4>部分项目情况</h4></div>

        <el-row :gutter="10">
          <el-col :span="8">
            <div class="auto-img">
              <img src="../../assets/img/product/integrate/pic_2.jpg" alt="项目情况">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="auto-img">
              <img src="../../assets/img/product/integrate/pic_3.jpg" alt="项目情况">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="auto-img">
              <img src="../../assets/img/product/integrate/pic_4.jpg" alt="项目情况">
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="10" class="mt-2">
          <el-col :span="8">
            <div class="auto-img">
              <img src="../../assets/img/product/integrate/pic_5.jpg" alt="项目情况">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="auto-img">
              <img src="../../assets/img/product/integrate/pic_6.jpg" alt="项目情况">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="auto-img">
              <img src="../../assets/img/product/integrate/pic_7.jpg" alt="项目情况">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big align-lt" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="primary" plain class="zdh-btn" @click="handleShow">立即咨询
                </el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
            <div class="demo-pic dzh-pic"></div>
          </el-col>
        </el-row>
        <div class="fz-sm align-ct" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '自动化系统集成-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控自动化系统集成-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-integrate {
  .banner-integrate {
    background: url("../../assets/img/product/integrate/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
